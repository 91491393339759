import { useState, useEffect, useRef } from 'react';
// -- Libs PrimeReact
import { SelectButton } from 'primereact/selectbutton';
import { Skeleton } from 'primereact/skeleton';
import { Message } from 'primereact/message';
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Nullable } from "primereact/ts-helpers";


import { CalculateUnits } from "../notes/utils/calculateUnits";
import { CalculateMinutes } from "../../commons/CalcultaeMinutes";
import { VoiceRecorder } from "../../commons"
import { getPlanImageUrl } from "../../../utils";
// New structure
import { CalculateAge } from "../../commons";
import { Active, TcmsNotesBill } from "../../../models";
import { useNotePaid, useNotePaidUnits, useNotePeriod } from "../../../hooks/modules/tcm";

interface Item {
    name: string;
    value: string;
}

// Añade esta función helper fuera del componente
const parseDate = (dateStr: string) => {
    if (!dateStr) return null;
    const [month, day, year] = dateStr.split('/');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
};

const BillingFinance = ({ active, relad }: Props) => {
    const { mutate: notePaid } = useNotePaid(relad);
    const { mutate: notePaidUnits } = useNotePaidUnits(relad);
    const { mutate: notePeriod } = useNotePeriod(relad);
    const [listTcm, setListTCM] = useState<TcmsNotesBill[] | undefined>([]);
    const [isLoading, setIsLoading] = useState(true);

    const [period, setPeriod] = useState<Nullable<(Date | null)[]>>(null);
    const prevPeriodRef = useRef('');

    const items: Item[] = [
        { name: 'Full', value: "Full" },
        { name: 'Partial', value: "Partial" },
        { name: 'Denied', value: "Denied" },
        { name: 'No Paid', value: "No Paid" }
    ];

    // ----------------
    useEffect(() => {
        // Inicializar con el periodo del active
        if (active?.activeUser?.WeekActive?.start && active?.activeUser?.WeekActive?.end) {
            const startDate = parseDate(active.activeUser.WeekActive.start);
            const endDate = parseDate(active.activeUser.WeekActive.end);
            if (startDate && endDate) {
                const currentPeriodStr = `${startDate}-${endDate}`;
                if (currentPeriodStr !== prevPeriodRef.current) {
                    setPeriod([startDate, endDate]);
                    prevPeriodRef.current = currentPeriodStr;
                }
            }
        }

        // Si hay un periodo seleccionado, hacer la petición
        if (period && period[0] && period[1]) {
            setIsLoading(true);
            notePeriod(
                {
                    period: period,
                },
                {
                    onSuccess: (data) => {
                        setListTCM(data);
                        setIsLoading(false);
                    },
                    onError: () => {
                        setIsLoading(false);
                    },
                }
            );
        }
    }, [period, active?.activeUser?.WeekActive]);

    return (
        <div className="w-full p-5 mt-10">
            <div className='w-full flex items-center justify-end bg-gray-50'>
                <b>Select period:</b>
                <Calendar
                    value={period}
                    onChange={(e) => setPeriod(e.value)}
                    selectionMode="range"
                    readOnlyInput
                    hideOnRangeSelection
                    showWeek
                    maxDate={parseDate(active?.activeUser?.WeekActive?.end || '') || undefined}
                    showIcon
                    pt={{
                        root: { className: "ml-2" },
                        input: { root: { className: "text-right border-primary border" } }
                    }}
                />
            </div>
            {!isLoading ? (
                listTcm?.length || 0 > 0 ? (
                    listTcm?.map((data) => {
                        return (
                            <div className="w-full mt-2">
                                <div className='w-full flex border-primary border bg-blue-100 items-center'>
                                    <div className="w-1/5 text-center p-2">
                                        <b>TCM/Credentials:</b>
                                    </div>
                                    <div className="w-4/5">
                                        <div className="w-full flex items-center">
                                            <div className="p-2 w-1/4">
                                                {data.billing?.tcm_full_name} / {data.billing?.tcm_credentials}
                                            </div>
                                            <div className="w-2/4">
                                                <div className="w-full flex text-center text-sm items-center">
                                                    <div className="p-2">
                                                        <b>Week:</b>
                                                    </div>
                                                    <div className="p-2 w-auto">
                                                        {data.week?.start} - {data.week?.end}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-2/4">
                                                <div className="w-full flex text-center text-sm items-center">
                                                    <div className="p-2">
                                                        <b>Billing created on:</b>
                                                    </div>
                                                    <div className="p-2 w-auto">
                                                        {data.billing?.date}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-1/4 flex justify-end items-center">
                                                <VoiceRecorder
                                                    relad={relad}
                                                    active={active}
                                                    to={active?.activeUser?.User?.ID.toString() || "0"}
                                                    module="tcm"
                                                    component="Billing"
                                                    id_component={data?.billing?.id.toString() || "0"}
                                                    mode='private'
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='border-primary border border-t-0 border-b-0'>
                                    {data.clients?.map((client) => {
                                        const age = CalculateAge({ dob: client.dob ?? "00/00/0000" });
                                        return (
                                            <div className='w-full'>
                                                {/* row 3 */}
                                                <div className="w-full flex bg-orange-200 border-primary border-b ">
                                                    <div
                                                        className="w-1/5 text-center text-sm border-r border-primary place-items-center p-2 hover:text-secondary-hover hover:cursor-pointer"
                                                        onClick={() => {
                                                            // setSelectedScm(client.scm);
                                                            // setSelectedScmNum(1);
                                                            // onInfo();
                                                        }}
                                                    >
                                                        <b>Client:</b> {client.first_name}{" "}{client.last_name}
                                                    </div>
                                                    <div className="w-4/5">
                                                        <div className="w-full flex">
                                                            <div className="w-full">
                                                                <div className="w-full flex text-center text-sm">
                                                                    <div className="p-2 w-32 border-primary border-r">
                                                                        <b>Date</b>
                                                                    </div>
                                                                    <div className="p-2 w-24 border-primary border-r">
                                                                        <b>CPT code</b>
                                                                    </div>
                                                                    <div className="p-2 w-20 border-primary border-r">
                                                                        <b>UNITS</b>
                                                                    </div>
                                                                    <div className="p-2 w-14 border-primary border-r">
                                                                        <b>LOC</b>
                                                                    </div>
                                                                    <div className="p-2 w-32 border-primary border-r">
                                                                        <b>Time In</b>
                                                                    </div>
                                                                    <div className="p-2 w-32 border-primary border-r">
                                                                        <b>Time Out</b>
                                                                    </div>
                                                                    <div className="p-2 w-20 border-primary border-r">
                                                                        <b>Time</b>
                                                                    </div>
                                                                    <div className="p-2 w-20 border-primary border-r">
                                                                        <b>Units</b>
                                                                    </div>
                                                                    <div className="p-2 justify-end text-right w-auto flex-grow">
                                                                        <b>Payment result</b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* -------------------------------- */}
                                                <div className="w-full flex border-primary border-r-0">
                                                    <div className="w-1/5 text-center text-sm bg-orange-100 border-r border-b border-primary relative content-center">
                                                        <div className="relative text-black w-full">
                                                            <div className="w-full flex">
                                                                <div className="w-1/2 text-right font-bold">MR#</div>
                                                                <div className="w-1/2 text-left pl-2">{client.scm.id}</div>
                                                            </div>
                                                            <div className="w-full flex">
                                                                <div className="w-1/2 text-right font-bold">M.H. Diagnosis:</div>
                                                                <div className="w-1/2 text-left pl-2">{client.scm.Mental.mental_primary}</div>
                                                            </div>
                                                            <div className="w-full flex">
                                                                <div className="w-1/2 text-right font-bold">Medicaid:</div>
                                                                <div className="w-1/2 text-left pl-2">{client.scm.Demografic.medicaid}</div>
                                                            </div>
                                                            <div className="w-full flex">
                                                                <div className="w-1/2 text-right font-bold">Medicare:</div>
                                                                <div className="w-1/2 text-left pl-2">{client.scm.Demografic.medicare}</div>
                                                            </div>
                                                            <div className="w-full flex">
                                                                <div className="w-1/2 text-right font-bold">Insurance Plan:</div>
                                                                <div className="w-1/2 text-left pl-2">{client.scm.sure_active.plan_name}</div>
                                                            </div>
                                                            <div className="w-full flex">
                                                                <div className="w-1/2 text-right font-bold">Insurance Id:</div>
                                                                <div className="w-1/2 text-left pl-2">{client.scm.sure_active.plan_id}</div>
                                                            </div>
                                                            <div className="w-full flex">
                                                                <div className="w-1/2 text-right font-bold">DOB:</div>
                                                                <div className="w-1/2 text-left pl-2">{client.scm.Demografic.dob}</div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="w-4/5 h-full border-primary relative" style={{ zIndex: 1 }}>
                                                        <div className="absolute inset-0"
                                                            style={{
                                                                backgroundImage: client.scm.sure_active.plan_name
                                                                    ? `url(${getPlanImageUrl(client.scm.sure_active.plan_name)})`
                                                                    : 'none',
                                                                backgroundSize: 'contain', // Ajusta para que la imagen se vea completa
                                                                backgroundRepeat: 'no-repeat', // Evita que la imagen se repita
                                                                backgroundPosition: 'top', // Centra la imagen
                                                                opacity: 0.1,
                                                                zIndex: -1 // Asegura que quede detrás de los otros elementos
                                                            }}
                                                        ></div>
                                                        {client.notes.map((note) => {
                                                            return (
                                                                <div>
                                                                    <div className="w-full flex border-primary border-b">
                                                                        <div className="w-full">
                                                                            <div className="w-full flex content-center align-middle text-center text-sm">
                                                                                <div
                                                                                    className="p-2 w-32 border-primary border-r place-content-center"
                                                                                    onClick={(e) => {
                                                                                        // setVisible(true);
                                                                                        // setActiveNote(note);
                                                                                        // setActiveClient(client);
                                                                                    }}
                                                                                >
                                                                                    <b className="border-primary border-b text-primary  hover:text-secondary ">
                                                                                        {note.date}
                                                                                    </b>
                                                                                </div>
                                                                                <div className="p-2 w-24 border-primary border-r place-content-center">
                                                                                    T1017 {age < 18 && "-HA"}
                                                                                </div>
                                                                                <div className="p-2 w-20 border-primary border-r place-content-center bg-orange-200 bg-opacity-50">
                                                                                    {note.units}
                                                                                </div>
                                                                                <div className="p-2 w-14 border-primary border-r place-content-center">
                                                                                    {note.location}
                                                                                </div>
                                                                                <div className="p-2 w-32 border-primary border-r place-content-center">
                                                                                    {note.timeIn}
                                                                                </div>
                                                                                <div className="p-2 w-32 border-primary border-r place-content-center">
                                                                                    {note.timeOut}
                                                                                </div>
                                                                                <div className="p-2 w-20 border-primary border-r place-content-center">
                                                                                    {CalculateMinutes({
                                                                                        firstHour: note.timeIn,
                                                                                        secondHour: note.timeOut,
                                                                                    })}{" "}
                                                                                    min
                                                                                </div>
                                                                                <div className="p-2 w-20 border-primary border-r place-content-center">
                                                                                    {CalculateUnits({
                                                                                        minutes: CalculateMinutes({
                                                                                            firstHour: note.timeIn,
                                                                                            secondHour: note.timeOut,
                                                                                        }),
                                                                                    })}
                                                                                </div>
                                                                                <div className="p-2 flex justify-end items-center w-auto flex-grow">
                                                                                    {note.paid === "Partial" && <div className=''>
                                                                                        <InputNumber
                                                                                            inputId="paidUnits"
                                                                                            value={note.paidUnits}
                                                                                            max={note.units}
                                                                                            onValueChange={(e: InputNumberValueChangeEvent) => {
                                                                                                const newValue = e.value ?? 0;

                                                                                                // Validar que no exceda las unidades totales
                                                                                                if (newValue > note.units) {
                                                                                                    return;
                                                                                                }

                                                                                                // Actualizar unidades pagadas
                                                                                                notePaidUnits({
                                                                                                    id: Number(note.ID),
                                                                                                    paidUnits: newValue
                                                                                                }, {
                                                                                                    onSuccess: () => {
                                                                                                        // Actualizar la lista local
                                                                                                        setListTCM(prevList =>
                                                                                                            prevList?.map(tcm => ({
                                                                                                                ...tcm,
                                                                                                                clients: tcm.clients?.map(client => ({
                                                                                                                    ...client,
                                                                                                                    notes: client.notes.map(n =>
                                                                                                                        n.ID === note.ID
                                                                                                                            ? { ...n, paidUnits: newValue }
                                                                                                                            : n
                                                                                                                    )
                                                                                                                }))
                                                                                                            }))
                                                                                                        );
                                                                                                        // Llamar a relad para actualizar datos del padre
                                                                                                        relad();
                                                                                                    }
                                                                                                });

                                                                                                // Actualizar estado de pago basado en las unidades
                                                                                                const newPaidStatus =
                                                                                                    newValue === 0 ? "No Paid" :
                                                                                                        newValue === note.units ? "Full" :
                                                                                                            "Partial";

                                                                                                notePaid({
                                                                                                    id: Number(note.ID),
                                                                                                    paid: newPaidStatus
                                                                                                }, {
                                                                                                    onSuccess: () => {
                                                                                                        setListTCM(prevList =>
                                                                                                            prevList?.map(tcm => ({
                                                                                                                ...tcm,
                                                                                                                clients: tcm.clients?.map(client => ({
                                                                                                                    ...client,
                                                                                                                    notes: client.notes.map(n =>
                                                                                                                        n.ID === note.ID
                                                                                                                            ? { ...n, paid: newPaidStatus }
                                                                                                                            : n
                                                                                                                    )
                                                                                                                }))
                                                                                                            }))
                                                                                                        );
                                                                                                        relad();
                                                                                                    }
                                                                                                });
                                                                                            }}
                                                                                            pt={{
                                                                                                root: { className: "mr-5" },
                                                                                                input: { root: { className: "text-right w-28" } }
                                                                                            }}
                                                                                            suffix={`${note.paidUnits === 1 ? ' Unit Paid' : ' Units Paid'}`}
                                                                                        />
                                                                                    </div>}
                                                                                    {data.billing?.tcms_signature !== "data:image/png;base64," ? (
                                                                                        <SelectButton
                                                                                            disabled={!note.invoiced}
                                                                                            value={note.paid}
                                                                                            onChange={(e) => {
                                                                                                notePaid({
                                                                                                    id: Number(note.ID),
                                                                                                    paid: e.value
                                                                                                }, {
                                                                                                    onSuccess: () => {
                                                                                                        // Actualizar la lista local
                                                                                                        setListTCM(prevList =>
                                                                                                            prevList?.map(tcm => ({
                                                                                                                ...tcm,
                                                                                                                clients: tcm.clients?.map(client => ({
                                                                                                                    ...client,
                                                                                                                    notes: client.notes.map(n =>
                                                                                                                        n.ID === note.ID
                                                                                                                            ? { ...n, paid: e.value }
                                                                                                                            : n
                                                                                                                    )
                                                                                                                }))
                                                                                                            }))
                                                                                                        );
                                                                                                        // Llamar a relad para actualizar datos del padre
                                                                                                        relad();
                                                                                                    }
                                                                                                });
                                                                                            }}
                                                                                            optionLabel="name"
                                                                                            options={items}
                                                                                        />
                                                                                    ) : (
                                                                                        <b className='text-red-500'>Pending supervision</b>
                                                                                    )}


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {note.timeOut_2 !== "" && (
                                                                        <div className="w-full flex border-primary border-b">
                                                                            <div className="w-full">
                                                                                <div className="w-full flex text-center text-sm">
                                                                                    <div
                                                                                        className="p-2 w-32 border-r text-gray-50"
                                                                                    >
                                                                                        {note.date}
                                                                                    </div>
                                                                                    <div className="p-2 w-24 border-r"></div>
                                                                                    <div className="p-2 w-20 border-primary border-r"></div>
                                                                                    <div className="p-2 w-14 border-primary border-r">
                                                                                        {note.location_2}
                                                                                    </div>
                                                                                    <div className="p-2 w-32 border-primary border-r">
                                                                                        {note.timeIn_2}
                                                                                    </div>
                                                                                    <div className="p-2 w-32 border-primary border-r">
                                                                                        {note.timeOut_2}
                                                                                    </div>
                                                                                    <div className="p-2 w-20 border-primary border-r">
                                                                                        {CalculateMinutes({
                                                                                            firstHour: note.timeIn_2,
                                                                                            secondHour: note.timeOut_2,
                                                                                        })}{" "}
                                                                                        min
                                                                                    </div>
                                                                                    <div className="p-2 w-20 border-primary border-r">
                                                                                        {CalculateUnits({
                                                                                            minutes: CalculateMinutes({
                                                                                                firstHour: note.timeIn_2,
                                                                                                secondHour: note.timeOut_2,
                                                                                            }),
                                                                                        })}
                                                                                    </div>

                                                                                    <div className="p-2 w-auto">

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {note.timeOut_3 !== "" && (
                                                                        <div className="w-full flex border-primary border-b">
                                                                            <div className="w-full">
                                                                                <div className="w-full flex text-center text-sm">
                                                                                    <div
                                                                                        className="p-2 w-32 border-r text-gray-50"
                                                                                    >
                                                                                        {note.date}
                                                                                    </div>
                                                                                    <div className="p-2 w-24 border-r"></div>
                                                                                    <div className="p-2 w-20 border-primary border-r"></div>
                                                                                    <div className="p-2 w-14 border-primary border-r">
                                                                                        {note.location_3}
                                                                                    </div>
                                                                                    <div className="p-2 w-32 border-primary border-r">
                                                                                        {note.timeIn_3}
                                                                                    </div>
                                                                                    <div className="p-2 w-32 border-primary border-r">
                                                                                        {note.timeOut_3}
                                                                                    </div>
                                                                                    <div className="p-2 w-20 border-primary border-r">
                                                                                        {CalculateMinutes({
                                                                                            firstHour: note.timeIn_3,
                                                                                            secondHour: note.timeOut_3,
                                                                                        })}{" "}
                                                                                        min
                                                                                    </div>
                                                                                    <div className="p-2 w-20 border-r border-primary">
                                                                                        {CalculateUnits({
                                                                                            minutes: CalculateMinutes({
                                                                                                firstHour: note.timeIn_3,
                                                                                                secondHour: note.timeOut_3,
                                                                                            }),
                                                                                        })}
                                                                                    </div>
                                                                                    <div className="p-2 w-auto">

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        })}

                                                    </div>
                                                </div>
                                                {/* -------------------------------------------------------------------------------------------------- */}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <Message text="No billing for this period" className="w-full mt-6 bg-gray-100 h-96" />
                )
            ) : (
                <div>
                    <Skeleton className="mb-2"></Skeleton>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="5rem" className="mb-2"></Skeleton>
                </div>
            )}

        </div>
    );
}
type Props = {
    active?: Active;
    relad(): void;
};
export { BillingFinance }