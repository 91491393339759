import { useState, useEffect, useRef } from 'react';
// -- Libs PrimeReact
import { SelectButton } from 'primereact/selectbutton';
import { Skeleton } from 'primereact/skeleton';
import { Message } from 'primereact/message';
import { FilterMatchMode } from 'primereact/api';
import { DataTable, DataTableExpandedRows, DataTableValueArray, DataTableFilterMeta } from 'primereact/datatable';
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Nullable } from "primereact/ts-helpers";
// -- Component
import { Dialog } from "primereact/dialog";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import { Badge } from 'primereact/badge';

import { Column } from 'primereact/column';
// import { CalculateUnits } from "../notes/utils/calculateUnits";
// import { CalculateMinutes } from "../../commons/CalcultaeMinutes";
// import { VoiceRecorder } from "../../commons"
// import { getPlanImageUrl } from "../../../utils";
import { PaymentNotesTCM } from "./paymentTCMNotes";
// New structure
import { CalculateAge, saveAsExcelFile } from "../../commons";

import { Active, PaymentsAllTcm, NotesPayments } from "../../../models";
import { useTcmAllPayments, useTcmAllPaymentsTCM } from "../../../hooks/modules/tcm";


type Props = {
  active?: Active;
  relad(): void;
  paymentsTcm: number;
};
interface Item {
  name: string;
  value: string;
}

const defaultFilters: DataTableFilterMeta = {
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
};


const PaymentTCM = ({ active, relad, paymentsTcm }: Props) => {
  const { tcmAllPaymentsTcm, reloadTcmAllPaymentsTcm, isLoadingTcmAllPaymentsTcm } = useTcmAllPaymentsTCM(paymentsTcm);



  const [visibleNotesPayments, setVisibleNotesPayments] = useState<boolean>(false);
  const [activeTcmPayments, setActiveTcmPayments] = useState<number>(0);
  const [filters, setFilters] = useState<DataTableFilterMeta>(defaultFilters);

  const [globalFilterValue, setGlobalFilterValue] = useState<string>('');
  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };

    // @ts-ignore
    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(tcmAllPaymentsTcm?.paymentsTcm ?? []);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });

      saveAsExcelFile(excelBuffer, 'PaymentsTCM');
    });
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-between items-center w-full" style={{ backgroundColor: "#F8F9FA" }}>

        <div className="flex w-1/4 items-center flex-wrap justify-content-center gap-3">
          <span className="p-input-icon-left">
            <i className="pi pi-search ml-2" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search"
              autoFocus
              pt={{
                root: { className: 'border-gray-400 p-3 pl-8' }
              }}
            />
          </span>

        </div>
        <div className="flex justify-end">
          <Button
            icon="pi pi-file-excel"
            label="Export"
            onClick={exportExcel}
            size='small'
            pt={{
              root: { className: 'bg-secondary' }
            }}
          />
        </div>
      </div>
    );
  };
  const header = renderHeader();
  // ------------------
  const Hours = (rowData) => {
    const totalHours = rowData.units_paid * 15 / 60;
    const hours = Math.floor(totalHours);
    const minutes = Math.round((totalHours - hours) * 60);

    return (
      <div className='flex justify-content-center gap-4'>
        {hours > 0 && `${hours} h `}
        {minutes > 0 && `${minutes} min`}
        {hours === 0 && minutes === 0 && '0 min'}
      </div>
    );
  };
  // ------------------
  const ColUnits = (rowData) => {
    return (
      <div className='justify-content-center gap-4'>
        {rowData.units_paid} of{" "}{rowData.units}
        {rowData.paid > 0 && <div>
          <b style={{ color: 'red', fontSize: '10px' }}>Pending: {rowData.units_pending} units</b>
        </div>}
      </div>
    );
  };
  // ------------------
  const Visible = (rowData) => {
    const [checked, setChecked] = useState<boolean>(rowData.visible);
    return (
      <div className='flex justify-content-center gap-4'>
        <InputSwitch
          checked={checked}
          onChange={(e: InputSwitchChangeEvent) => setChecked(e.value)}
        />
      </div>
    );
  };// ------------------
  const Approved = (rowData) => {
    const [checked, setChecked] = useState<boolean>(rowData.approved);
    return (
      <div className='flex justify-content-center gap-4'>
        <InputSwitch
          checked={checked}
          onChange={(e: InputSwitchChangeEvent) => setChecked(e.value)}
        />
      </div>
    );
  };
  const Notes = (rowData) => {
    // const [checked, setChecked] = useState<boolean>(rowData.approved);
    return (
      <div className='flex justify-content-center gap-4'>
        <i
          onClick={
            () => {
              setVisibleNotesPayments(true);
              setActiveTcmPayments(rowData.id);
            }}
          className="pi pi-list p-overlay-badge cursor-pointer" style={{ fontSize: '2rem' }}>
          <Badge
            // value={rowData.NotesPayments.length}
            severity="info"
            pt={{
              root: { className: 'cursor-pointer' }
            }}
          />
        </i>
      </div>
    );
  };

  return (
    <div className="w-full">
      {tcmAllPaymentsTcm?.paymentsTcm.length || 0 > 0 ? (
        <DataTable
          value={tcmAllPaymentsTcm?.paymentsTcm}
          // onRowClick={onInfo}
          paginator
          rowHover
          stripedRows
          rows={10}
          dataKey="id"
          filters={filters}
          globalFilterFields={['tcm_name', 'rate', 'paid', 'remaining_rate', 'profit', 'vacation']}
          header={header}
          // selectionMode="single"
          sortMode="multiple"
          emptyMessage="No customers found."
        >
          <Column field="tcm_name" header="Employee Name" sortable />
          <Column field="units_paid" header="Units" body={ColUnits} sortable />
          <Column field="hours_paid" header="Hours" sortable body={Hours} />
          <Column field="rate" header="Rate" body={(rowData) => `$${rowData.rate.toFixed(2)}`} sortable />
          <Column field="paid" header="Total" body={(rowData) => `$${rowData.paid.toFixed(2)}`} sortable className='bg-orange-100' />
          {/* <Column field="units" header="units" sortable /> */}
          {/* <Column field="units_pending" header="units_pending" sortable /> */}
          <Column field="remaining_rate" header="Rate" body={(rowData) => `$${rowData.remaining_rate.toFixed(2)}`} sortable />
          <Column field="profit" header="Profit" body={(rowData) => `$${rowData.profit.toFixed(2)}`} sortable className='bg-green-100' />
          <Column field="vacation" header="Vacation" body={(rowData) => `$${rowData.vacation.toFixed(2)}`} sortable />
          <Column field="id" header="Notes" body={Notes} />
          <Column field="id" header="Visible" body={Visible} />
          <Column field="id" header="Approved" body={Approved} />
          <Column field="id" header="Liquidated" body={Approved} />

          {/* <Column field="dob" header="Admission" body={Services} /> */}
        </DataTable>
      ) : (
        <Message text="No TCM payments" className="w-full mt-6 bg-gray-100 h-96" />
      )
      }

      <Dialog
        header="NotesPayments"
        visible={visibleNotesPayments}
        maximizable
        style={{ width: "80vw" }}
        breakpoints={{ "960px": "70vw", "641px": "90vw" }}
        onHide={() => setVisibleNotesPayments(false)}
      >
        <PaymentNotesTCM tcmPayments={activeTcmPayments} active={active} relad={relad} />
      </Dialog>
    </div>
  );
}

export { PaymentTCM }