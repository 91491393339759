import React, { useState, useRef } from 'react';
import { DataTable, DataTableRowEvent, DataTableFilterMeta } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { useCoreUsers } from "../../hooks";
import { Affix } from "antd";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Dialog } from 'primereact/dialog';

interface User {
    uid: string;
    email: string;
    roll: string;
    status: string;
    approved: boolean;
    change_password: boolean;
}

const Workers = () => {
    const { users, isLoading } = useCoreUsers();
    const toast = useRef<Toast>(null);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const onRowClick = (event: DataTableRowEvent) => {
        setSelectedUser(event.data as User);
        setDialogVisible(true);
    };

    const header = (
        <div className='w-full'>
            <Affix offsetTop={44} onChange={(affixed) => console.log(affixed)}>
                <div className="flex justify-between items-center w-full" style={{ backgroundColor: "#F8F9FA" }}>
                    <div className="flex w-1/4 items-center">
                        <span className="p-input-icon-left w-full">
                            <i className="pi pi-search pl-2" />
                            <InputText placeholder="Keyword Search" className='w-full pl-8' />
                        </span>
                    </div>
                </div>
            </Affix>
        </div>
    );

    const defaultFilters: DataTableFilterMeta = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        email: {
            operator: FilterOperator.AND,
            constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
        }        
    };

    const [filters, setFilters] = useState<DataTableFilterMeta>(defaultFilters);


    return (
        <div className='w-full mt-10'>
            <Toast ref={toast} />

            <DataTable
                value={users?.users}
                loading={isLoading}
                filters={filters}
                onRowClick={onRowClick}
                header={header}
                tableStyle={{ minWidth: '60rem' }}
            >
                <Column field="email" header="Email" sortable />
                <Column field="roll" header="Rol" sortable />
                <Column field="status" header="Estado" sortable />
                <Column field="approved" header="Aprobado" sortable />
                <Column field="change_password" header="Cambio de Password" sortable />
            </DataTable>

            <Dialog
                header="Staff User Information"
                visible={dialogVisible}
                style={{ width: '95vw' }}
                onHide={() => setDialogVisible(false)}
            >
                {selectedUser && (
                    <div className="w-full flex p-4">
                        <div className="w-1/6 mb-3 border border-black border-r-0 pl-2">
                            <label className="font-bold">Usuario:</label>
                            <div>{selectedUser.uid}</div>
                        </div>
                        <div className="w-1/6 mb-3 border border-black border-r-0 pl-2">
                            <label className="font-bold">Email:</label>
                            <div>{selectedUser.email}</div>
                        </div>
                        <div className="w-1/6 mb-3 border border-black border-r-0 pl-2">
                            <label className="font-bold">Rol:</label>
                            <div>{selectedUser.roll}</div>
                        </div>
                        <div className="w-1/6 mb-3 border border-black border-r-0 pl-2">
                            <label className="font-bold">Estado:</label>
                            <div>{selectedUser.status ? "Activo" : "Inactivo"}</div>
                        </div>
                        <div className="w-1/6 mb-3 border border-black border-r-0 pl-2">
                            <label className="font-bold">Aprobado:</label>
                            <div>{selectedUser.approved ? "Yes" : "No"}</div>
                        </div>
                        <div className="w-1/6 mb-3 border border-black pl-2">
                            <label className="font-bold">Cambio de Password:</label>
                            <div>{selectedUser.change_password ? "Yes" : "No"}</div>
                        </div>
                    </div>
                )}
            </Dialog>
        </div>
    );
}

export { Workers };